<template>
  <div ref="chart" :style="{ height: height, width: width }"></div>
</template>
<script>
import { debounce } from "lodash";
const echarts = require("echarts");
export default {
  components: {},
  props: {
    chartData: {
      type: Object,
      default: {
        xAxisData: [],
        seriesData: [],
      },
    },
    legendData: {
      type: Array,
      default: [],
    },
    units: {
      type: Array,
      default: [],
    },
    yAxis: {
      type: Array,
      default: [],
    },
    yAxisIndex: {
      type: Array,
      default: 0,
    },
    width: {
      type: String,
      default: "100%",
    },
    height: {
      type: String,
      default: "100%",
    },
    istrue:{
      type: String,
      default: "100%",
    },
    type:{
      type: String,
      default: '1'
    },
  },
  data() {
    return {
      color: [
        "rgba(35,142,255,0.7)",
        "rgba(62,235,255,0.7)",
        "rgba(255,234,0,0.7)",
        "rgba(52,250,89,0.7)",
        "rgba(116,70,256,0.7)",
      ],
      color2: [
        {
          back: {
            type: "linear",
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            colorStops: [
              {
                offset: 1,
                color: "rgba(35,142,255,0.1)", // 0% 处的颜色
              },
              {
                offset: 0,
                color: "rgba(35,142,255,0.65)", // 100% 处的颜色
              },
            ],
            global: false, // 缺省为 false
          },
          border: {
            type: "linear",
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            colorStops: [
              {
                offset: 0,
                color: "rgba(44,197,255,1)", // 0% 处的颜色
              },
              {
                offset: 1,
                color: "rgba(44,197,255,0.1)", // 100% 处的颜色
              },
            ],
            global: false, // 缺省为 false
          },
        },
        {
          back: {
            type: "linear",
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            colorStops: [
              {
                offset: 1,
                color: "rgba(35,242,255,0.1)", // 0% 处的颜色
              },
              {
                offset: 0,
                color: "rgba(43,228,250,0.56)", // 100% 处的颜色
              },
            ],
            global: false, // 缺省为 false
          },
          border: {
            type: "linear",
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            colorStops: [
              {
                offset: 0,
                color: "rgba(43,233,255,1)", // 0% 处的颜色
              },
              {
                offset: 1,
                color: "rgba(43,233,255,0.1)", // 100% 处的颜色
              },
            ],
            global: false, // 缺省为 false
          },
        },
      ],
    };
  },
  computed: {},
  watch: {
    chartData: {
      handler(val, olVal) {
        //需要在这从新执行一次
        if (val) {
          this.initCharts();
        }
      },
      deep: true,
    },
  },

  methods: {
    initCharts() {
      let _this = this;
      this.chart = echarts.init(this.$refs.chart);
      let arr = _this.chartData.seriesData || [];
      let seriesData = [];
      arr.forEach((item, i) => {
        seriesData.push({
          name: item.name,
          yAxisIndex: _this.yAxisIndex[i],
          type: item.type,
          showSymbol: false,
          symbol: "circle",
          barWidth:'15',
          barGap:'50%',
          smooth: true,
          lineStyle: {
            color: _this.color[i],
            width: 1,
          },
          itemStyle: {
            color: item.type == "line" ? _this.color[i] : _this.color2[i].back,
            borderWidth: 1,
            borderColor: item.type == "line" ? _this.color[i] : _this.color2[i].border,
          },
          areaStyle: {
            color: {
              type: "linear",
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [
                {
                  offset: 0,
                  color: "rgba(62,235,255,0.1)",
                },
                {
                  offset: 1,
                  color: "rgba(62,235,255,0)", // 100% 处的颜色
                },
              ],
              global: false, // 缺省为 false
            },
          },
          label: {
            formatter: "{a}" + item.unit,
          },
          data: item.data,
        });
      });
      let options = {
        tooltip: {
          transitionDuration: 0,
          trigger: "axis",
          backgroundColor: "#09214d",
          borderWidth: 1,
          borderColor: "#7ccaff",
          axisPointer: {
            type: "line",
            lineStyle: {
              type: "dashed",
              color: "rgba(102,163,215,0.3)",
            },
          },
          formatter: function (params) {
            let html = "";
            let num = (
              Math.round(
                parseFloat(Number(params[0].value) + Number(params[1].value)) *
                  100
              ) / 100
            )
              .toString()
              .split(".");
            html += params[0].name + "<br>";

            _this.istrue==0?
            html +=
              "交易总额" +
              ":<span style='color:" +
              params.color +
              "'>" +
              (num.length == 1 ? num + ".00" : num) +
              "</span>元<br>":'';
            params.forEach((res, i) => {
              //html+= res.marker+res.seriesName+":<span style='color:"+res.color+"'>"+res.value+'</span>'+_this.units[i]+'<br>'
              if (res.seriesName.indexOf("笔") != -1) {
                html +=
                   '<span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:' +
                  _this.color[i] +
                  ';"></span>' +
                  res.seriesName +
                  ":<span style='color:" +
                  _this.color[i] +
                  "'>" +
                  res.value +
                  "</span>笔<br>";
              } else if (res.seriesName.indexOf("金额") != -1) {
                html +=
                  '<span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:' +
                  _this.color[i] +
                  ';"></span>' +
                  res.seriesName +
                  ":<span style='color:" +
                  _this.color[i] +
                  "'>" +
                  res.value +
                  "</span>元<br>";
              } else if (res.seriesName.indexOf("比") != -1||res.seriesName.indexOf("率") != -1) {
                html +=
                    '<span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:' +
                  _this.color[i] +
                  ';"></span>' +
                  res.seriesName +
                  ":<span style='color:" +
                  _this.color[i] +
                  "'>" +
                  res.value +
                  "</span>%<br>";
              } else {
                html +=
                   '<span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:' +
                  _this.color[i] +
                  ';"></span>' +
                  res.seriesName +
                  ":<span style='color:" +
                  _this.color[i] +
                  "'>" +
                  res.value +
                  "</span>" +
                  _this.units[i] +
                  "<br>";
              }
            });
            return html;
          },
        },
        title: {
          // text: '需求资源数',
          textStyle: {
            color: "#507998",
            fontWeight: "normal",
            fontSize: 16,
          },
        },
        grid: {
          left: "40",
          right: "40",
          bottom: "40",
          containLabel: true,
        },
        legend: {
          top: 10,
          right: "4%",
          itemGap: 40,
          icon: "circle",
          data: _this.legendData,
          textStyle: {
            fontSize: 14,
            color: "#5bd5f7",
          },
        },
        dataZoom: [],
        xAxis: {
          data: _this.chartData.xAxisData,
          splitNumber: 7,
          splitLine: {
            lineStyle: {
              type: "dashed",
            },
          },
          axisLine: {
            lineStyle: {
              color: "rgba(102,163,255,0.4)",
            },
          },
          axisLabel: {
            interval: (this.type == 3 || this.type == 4) ? 2 : 'auto',
            showMaxLabel:  true,
            // showMinLabel: (this.type == 3 || this.type == 4) ? false : true,
            textStyle: {
              fontSize: 14,
              color: "rgba(91,213,247,0.6)",
            },
          },
          axisTick: {
            show: false,
          },
        },
        yAxis: [],
        // '资源申请数','已提供资源数','需求项超时数'
        series: seriesData,
      };
      if (arr[0].data && arr[0].data.length >= 20) {
        options.dataZoom = [
          {
            bottom: 20,
            type: "slider",
            textStyle: false,
            height: 10,//这里可以设置dataZoom的尺寸
            zoomLock: true,
            startValue: arr[0].data.length - 16,
            endValue: arr[0].data.length - 1,
            borderColor: "#03c0f7",
            fillerColor: "#50f4ee",
            handleStyle: {
              opacity: 0,
            },
          },
        ];
      } else {
        options.dataZoom = [];
      }
      this.yAxis.map((item, i) => {
        if (item == "%") {
          options.yAxis.push({
            //name:item,
            min: 0,
            max: 100,
            scale: true,
            splitLine: {
              lineStyle: {
                color: "rgba(102,163,255,0.2)",
                type: "solid",
              },
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: "rgba(102,163,255,0.4)",
              },
            },
            axisLabel: {
              formatter: "{value}" + item,
              textStyle: {
                fontSize: 14,
                color: "rgba(91,213,247,0.6)",
              },
            },
            axisTick: {
              show: false,
            },
          });
        } else {
          options.yAxis.push({
            //name:item,
            min: null,
            max: null,
            scale: true,
            splitLine: {
              lineStyle: {
                color: "rgba(102,163,255,0.2)",
                type: "solid",
              },
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: "rgba(102,163,255,0.4)",
              },
            },
            axisLabel: {
              formatter: "{value}",
              textStyle: {
                fontSize: 14,
                color: "rgba(91,213,247,0.6)",
              },
            },
            axisTick: {
              show: false,
            },
          });
        }
      });
      this.chart.clear();
      // 绘制图表
      this.chart.setOption(options);
    },
  },
  beforeDestroy() {
    if (!this.chart) {
      return;
    }
    window.removeEventListener("resize", this.resizeHandler);
    this.chart.dispose();
    this.chart = null;
  },
  mounted() {
    this.initCharts();
    this.resizeHandler = debounce(() => {
      if (this.chart) {
        this.chart.resize();
      }
    }, 100);
    window.addEventListener("resize", this.resizeHandler);
  },
};
</script>
