<template>
  <div class="dateSelect-wrap">
    <div class="dateSelect-top">
      <el-select v-model="year" placeholder="请选择"  class="dateSelect-select year" @change="this.yearChange">
        <el-option
            v-for="(item , i) in yearLen"
            :key="item"
            :label="new Date(initDate).getFullYear() - (yearLen - 1) + i + '年'"
            :value="new Date(initDate).getFullYear() - (yearLen - 1) + i"
        ></el-option>
      </el-select>

      <div :class="`dateSelect-beforeMonth ${leftMonthDisable ? 'disable' :''}`" @click="beforeMonth"></div>
      <el-select v-model="month" placeholder="请选择"  class="dateSelect-select month" @change="this.monthChange">
        <el-option
            v-for="(item , i) in monthLen"
            :key="item"
            :label="monthLen - (monthLen - i - 1) + '月'"
            :value="monthLen - (monthLen - i - 1)"
        ></el-option>
      </el-select>
      <div :class="`dateSelect-afterMonth ${rightMonthDisable? 'disable' : ''}`" @click="afterMonth"></div>

      <el-button @click="returnToday" class="dateSelect-select day">重置</el-button>
    </div>

    <div class="dateSelect-content">
      <ul>
        <li v-for="(item,ind) in dateList" :key="item" :class="{'active': activeDate === item,'disable': item > dataAdd0(new Date(initDate))}" :style="{'left':   120 * ind  - 40 + 'px' }">
          <p class="dateSelect-icon"></p>
          <div class="dateSelect-ruler"></div>
          <span class="dateSelect-name">{{item}}</span>
        </li>
      </ul>
      <div class="dateSelect-left" @click="this.beforeDay"></div>
      <div :class="`dateSelect-right ${isDisabled ? 'disable' : ''}`" @click="this.afterDay"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "dateSelect",
  data() {
    // let initDate = new Date().getTime() - (24 * 60 * 60 * 1000);
    let initDate = new Date().getTime();
    let yearLen = new Date().getFullYear() - new Date('2016').getFullYear() + 1;
    return {
      yearLen:yearLen,
      dateList: [],
      year: new Date(initDate).getFullYear(),
      month: new Date(initDate).getMonth() + 1,
      day: new Date(initDate).getDate(),
      monthLen: new Date(initDate).getMonth() + 1,
      leftMonthDisable: new Date(initDate).getMonth() === 0 ? true : false,
      rightMonthDisable: true,
      initDate: initDate,
      liLeft: -40,
    }
  },
  computed: {
    activeDate() {
      let month = (this.month+ '').padStart(2,'0');
      let day = (this.day+ '').padStart(2,'0');
      if(this.year === new Date(this.initDate).getFullYear()){
        this.monthLen = new Date().getMonth() + 1;
        this.month = new Date().getMonth() + 1;
      }else{
        this.monthLen = 12
      }
      this.leftMonthDisable = this.month == 1 ? true : false;
      this.rightMonthDisable = this.monthLen == this.month ? true : false;

      return this.year + '-' + month + '-' + day;
    },
    isDisabled() {
      return this.dataAdd0(new Date(this.initDate)) == this.activeDate ? true : false;
    }
  },
  watch: {
    activeDate: function (val){
      // console.log(val,'date=====')
      this.$emit("dateChange",val)
    }
  },
  mounted() {
    this.getDateList(this.year,this.month,this.day)
    // this.$emit("dateChange",this.activeDate)
  },
  methods: {
    //选择年份
    yearChange(item){
      console.log(item,'----')
      this.month = 1;
      this.day = 1;
      this.getDateList(this.year,this.month,this.day)
    },
    //选择月份
    monthChange(item){
      console.log(item,'----222')
      this.day = 1;
      this.getDateList(this.year,this.month,this.day)
    },
    //返回今天
    returnToday(){
      this.year = new Date(this.initDate).getFullYear();
      this.month = new Date(this.initDate).getMonth() + 1;
      this.day = new Date(this.initDate).getDate();
      this.getDateList(this.year,this.month,this.day)
    },
    //前一天
    beforeDay(){
      // this.dateList[0]
      var stime = new Date(this.dateList[0]).getTime() - (24 * 60 * 60 * 1000);
      // console.log(this.getDiffdate(this.dataAdd0(stime),this.dataAdd0(stime)))
      let beforeArr = this.getDiffdate(this.dataAdd0(stime),this.dataAdd0(stime));
      this.dateList.unshift(beforeArr[0])
      setTimeout(()=>{
        this.dateList.pop()
        this.year = this.dateList[4].split('-')[0] - 0
        this.month = this.dateList[4].split('-')[1] - 0
        this.day = this.dateList[4].split('-')[2] - 0;
      })

      // console.log(this.dateList)
    },
    //后一天
    afterDay(){
      if(this.isDisabled) return false;
      var etime = new Date(this.dateList[this.dateList.length - 1]).getTime() + (24 * 60 * 60 * 1000);
      let endArr = this.getDiffdate(this.dataAdd0(etime),this.dataAdd0(etime));
        this.dateList.push(endArr[0])

        setTimeout(()=>{
          this.dateList.splice(0,1)
          this.year = this.dateList[4].split('-')[0] - 0
          this.month = this.dateList[4].split('-')[1] - 0
          this.day = this.dateList[4].split('-')[2] - 0
        })

    },
    //前个月
    beforeMonth(){
      this.month--;
      if(this.month < 1){
        this.month = 1;
      }
      this.monthChange()
    },
    //后个月
    afterMonth(){
      this.month++;
      if(this.month >this.monthLen){
        this.month = this.monthLen;
      }
      this.monthChange()
    },
    //获取日期区间
    getDateList(year,month,day){
      year = year + '';
      month = (month+ '').padStart(2,'0');
      day = (day+ '').padStart(2,'0');
      let date = year + '-' + month + '-' + day;
      // console.log(date)
      var stime = new Date(date).getTime() - (24 * 60 * 60 * 1000 * 4); //开始日期
      var etime = new Date(date).getTime() + (24 * 60 * 60 * 1000 * 4); //结束日期
      // console.log(stime)
      // console.log(etime)
      this.dateList = this.getDiffdate(this.dataAdd0(stime),this.dataAdd0(etime))
    },
    //日期补0，传时间对象或时间戳
    dataAdd0(date){
      var year = new Date(date).getFullYear() + '-';
      var month = (new Date(date).getMonth() + 1 + '').padStart(2,'0') + '-';
      var day = (new Date(date).getDate() + '').padStart(2,'0');
      return year + month + day;
    },
    //获取两日期之间日期列表函数
    getDiffdate(stime, etime) {
      //初始化日期列表，数组
      var diffdate = new Array();
      var i = 0;
      //开始日期小于等于结束日期,并循环
      while (stime <= etime) {
        diffdate[i] = stime;
        //获取开始日期时间戳
        var stime_ts = new Date(stime).getTime();
        //增加一天时间戳后的日期
        var next_date = stime_ts + (24 * 60 * 60 * 1000);
        stime = this.dataAdd0(next_date);
        // console.log("times",stime)
        //增加数组key
        i++;
      }
      // console.log(diffdate);
      return diffdate;
    }
  }
}
</script>

<style scoped lang="scss">
.dateSelect-wrap{
  margin-bottom: 68px;
  .dateSelect-top{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
    .dateSelect-select{
      &.year{
        width: 130px;
      }
      &.month{
        width: 100px;
      }
      &.day{
        width: 100px;
        border-radius: 0;
        padding: 1px 0 0;
        line-height: 29px;
        font-size: 16px;
        border: 1px solid rgb(35, 142, 255);
        background: rgba(35, 142, 255, 0.302);
        box-shadow: 0 0 7px rgba(35, 142, 255, 0.302) inset;
        color: #fff;
      }
      &::v-deep{
        .el-input__inner{
          border-radius: 0;
          font-size: 16px;
          color: #fff;
          border: 1px solid rgb(35, 142, 255) !important;
          background-color: rgba(35, 142, 255, 0.302);
          box-shadow: 0 0 7px rgba(35, 142, 255, 0.302) inset;
        }
      }

    }
    .dateSelect-beforeMonth , .dateSelect-afterMonth{
      width: 11px;
      height: 17px;
      margin-left: 30px;
      margin-right: 20px;
      background: url("../../assets/imgs/dateSelect/month-left.png");
      cursor: pointer;
      &:hover{
        transform: scale(1.2);
      }
      &.disable{
        cursor: not-allowed;
      }
    }
    .dateSelect-afterMonth{
      margin-left: 20px;
      margin-right: 30px;
      transform: rotate(180deg);
      &:hover{
        transform: rotate(180deg) scale(1.2);
      }
    }
  }

  .dateSelect-content{
    margin: 0 auto;
    width: 1000px;
    height: 110px;
    position: relative;
    ul{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      padding-top: 27px;
      display: flex;
      overflow: hidden;
      transition: all 0.3s;
      &::before,&::after{
        position: absolute;
        content: '';
        width: 150px;
        height: 100%;
        left: 0;
        top: 0;
        z-index: 5;
        background: linear-gradient(to right,rgba(6,24,48,1),rgba(6,24,48,0));
      }
      &::after{
        left: initial;
        right: 0px;
        background: linear-gradient(to right,rgba(6,24,48,0),rgba(6,24,48,1));
      }
      &> li:first-child{
        left: -40px;
      }
      li{
        user-select: none;
        position: absolute;
        top: 27px;
        z-index: 3;
        text-align: center;
        color: #238eff;
        font-size: 16px;
        line-height: 1;
        transition: all 0.3s;
        .dateSelect-icon{
          transition: all 0.3s;
          margin: 0 auto;
          width: 16px;
          height: 16px;
          margin-bottom: 7px;
          background: url("../../assets/imgs/dateSelect/day-icon.png") no-repeat;
        }
        .dateSelect-ruler{
          width: 120px;
          height: 25px;
          margin-bottom: 18px;
          background: url("../../assets/imgs/dateSelect/ruler.png") no-repeat;
        }
        &.active{
          color: #fdf94a;
          //transition: all 0.3s;
          .dateSelect-icon{
            transition: all 0.3s;
            width: 42px;
            height: 40px;
            margin-top: -24px;
            background: url("../../assets/imgs/dateSelect/star.png") no-repeat;
          }
        }
        &.disable{
          color: #a0a0a0;
          .dateSelect-icon{
            background: url("../../assets/imgs/dateSelect/day-icon-disable.png") no-repeat;
          }
        }
      }
    }
    .dateSelect-left,.dateSelect-right{
      cursor: pointer;
      position: absolute;
      z-index: 5;
      top: 55px;
      left: 20px;
      width: 37px;
      height: 27px;
      background: url("../../assets/imgs/dateSelect/left.png") no-repeat ;
    }
    .dateSelect-right{
      left: initial;
      right: 20px;
      transform: rotate(180deg);
      background: url("../../assets/imgs/dateSelect/left.png") no-repeat ;
      &.disable{
        cursor: not-allowed;
        transform: rotate(0deg);
        background: url("../../assets/imgs/dateSelect/right.png") no-repeat ;
      }
    }
  }
}
</style>
