<template>
  <div class="homeSupervise-item-wrap">
    <ul>
      <li v-for="(item,ind) in itemList" :key="ind">
        <div class="item-title"></div>
        <div class="item-line" v-if="item.hasOwnProperty('tradeMoney')">
          <span>交易资金</span>
          <span>{{item.tradeMoney}}<span>元</span></span>
        </div>
        <div class="item-line center" v-if="item.hasOwnProperty('depositScale')">
          <span>{{type == 3 ? '托管率' : '存管率'}}</span>
          <div class="item-line-right">
            <div class="item-progress">
              <span :style="{width: item.depositScale + '%'}"></span>
            </div>
            <span class="item-progress-title">{{item.depositScale}}<span>%</span></span>
          </div>
        </div>

        <div class="item-line center" v-if="item.hasOwnProperty('rateOfUnderwrite0')">
          <span>{{type == 4 ? '担保率' : '承保率'}}</span>
          <div class="item-line-right">
            <div class="item-progress">
              <span :style="{width: item.rateOfUnderwrite0 + '%'}"></span>
            </div>
            <span class="item-progress-title">{{item.rateOfUnderwrite0}}<span>%</span></span>
          </div>
        </div>

        <div class="item-line" v-if="item.hasOwnProperty('shouldDepositMoney')">
          <span>{{type == 3 ? '最低托管资金' : '最低存管资金'}}</span>
          <span>{{item.shouldDepositMoney}}<span>元</span></span>
        </div>
        <div class="item-line" v-if="item.hasOwnProperty('shouldDepositScale')">
          <span>{{type == 3 ? '初始托管率' : '初始存管率'}}</span>
          <div class="item-line-right">
            <div class="item-progress" v-if="item.shouldDepositScale !== '--'">
              <span :style="{width: item.shouldDepositScale + '%'}"></span>
            </div>
            <span v-if="item.shouldDepositScale !== '--'" class="item-progress-title">{{item.shouldDepositScale}}<span>%</span></span>
            <span v-if="item.shouldDepositScale === '--'" class="item-progress-title">{{'20%~100%'}}</span>
          </div>
        </div>
        <div class="item-line" v-if="item.hasOwnProperty('realityDepositMoney')">
          <span>{{type == 3 ? '当前托管资金' : '当前存管资金'}}</span>
          <span>{{item.realityDepositMoney}}<span>元</span></span>
        </div>
        <div class="item-line" v-if="item.hasOwnProperty('realityDepositScale')">
          <span>{{type == 3 ? '当前托管率' : '当前存管率'}}</span>
          <div class="item-line-right">
            <div class="item-progress">
              <span :style="{width: item.realityDepositScale + '%'}"></span>
            </div>
            <span class="item-progress-title">{{item.realityDepositScale}}<span>%</span></span>
          </div>
        </div>

        <div class="item-line" v-if="item.hasOwnProperty('namountOfDeposit')">
          <span>存管资金</span>
          <span>{{item.namountOfDeposit}}<span>元</span></span>
        </div>

        <div class="item-line center" v-if="item.hasOwnProperty('nrateOfDeposit')">
          <span>存管率</span>
          <div class="item-line-right">
            <div class="item-progress">
              <span :style="{width: item.nrateOfDeposit + '%'}"></span>
            </div>
            <span class="item-progress-title">{{item.nrateOfDeposit}}<span>%</span></span>
          </div>
        </div>

        <div class="item-line" v-if="item.hasOwnProperty('amountOfInsured1')">
          <span>{{type == 4 ? '担保金额' : '保险金额'}}</span>
          <span>{{item.amountOfInsured1}}<span>元</span></span>
        </div>
        <div class="item-line center" v-if="item.hasOwnProperty('rateOfUnderwrite1')">
          <span>{{type == 4 ? '担保率' : '承保率'}}</span>
          <div class="item-line-right">
            <div class="item-progress">
              <span :style="{width: item.rateOfUnderwrite1 + '%'}"></span>
            </div>
            <span class="item-progress-title">{{item.rateOfUnderwrite1}}<span>%</span></span>
          </div>
        </div>

        <div class="item-line" v-if="item.hasOwnProperty('amountOfInsuredN')">
          <span>{{type == 4 ? '担保金额' : '保险金额'}}</span>
          <span>{{item.amountOfInsuredN}}<span>元</span></span>
        </div>
        <div class="item-line center" v-if="item.hasOwnProperty('rateOfUnderwriteN')">
          <span>{{type == 4 ? '担保率' : '承保率'}}</span>
          <div class="item-line-right">
            <div class="item-progress">
              <span :style="{width: item.rateOfUnderwriteN + '%'}"></span>
            </div>
            <span class="item-progress-title">{{item.rateOfUnderwriteN}}<span>%</span></span>
          </div>
        </div>

        <div class="item-line chart">
          <span>兑付比</span>
<!--          <span>{{item.cashScale}}</span>-->
          <PieSmallChart class="pie-chart"
              :chartData="[
                { name: '', value: item.cashScale },
                { name: '', value: 100 - item.cashScale },
              ]"
              :titleName="item.cashScale + ''"
              :colors="['#00c6ff', '#03425e']"
          ></PieSmallChart>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import PieSmallChart from "@/components/charts/pieSmall"; //饼图
export default {
  name: "homeSupervise-item",
  components:{
    PieSmallChart
  },
  props:{
    itemList:{
      type: Array,
      default:()=>{
        return [{
          name:"T+0",
          tradeMoney:'0',
          depositScale:'0',
          cashScale:'0.00'
        },{
          name:"T+1",
          shouldDepositMoney:'0',
          shouldDepositScale:'0',
          realityDepositMoney:'0',
          realityDepositScale:'0',
          cashScale:'0.00'
        },{
          name:"TN-1",
          shouldDepositMoney:'0',
          shouldDepositScale:'0',
          realityDepositMoney:'0',
          realityDepositScale:'0',
          cashScale:'0.00'
        }]
      }
    },
    type:{
      type: [String,Number],
      default: 1
    }
  },
  data() {
    return {

    }
  },


  mounted() {

  },
  methods: {}
}
</script>

<style scoped lang="scss">
.homeSupervise-item-wrap{
  &>ul{
    width: 1000px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    li{
      width: 300px;
      height: 384px;
      background: url("../../assets/imgs/homeSupervise-item/item-bg.png");
      font-size: 16px;
      color: #b0cdf6;
      .item-title{
        height: 44px;
        margin-top: 28px;
      }
      &>.item-line:first-child{
        margin-top: 35px;
      }
      .item-line{
        line-height: 1;
        margin-top: 27px;
        display: flex;
        align-items: center;
        &.chart{
          margin-top: 0px;
        }
        &.center{
          margin-top: 75px;
          margin-bottom: 50px;
        }
        &>span:first-child{
          width: 106px;
          text-align: right;
          margin-right: 15px;
        }
        &>span:last-child{
          flex: 1;
          font-size: 24px;
          //color: #17b4ff;
          background: linear-gradient(to bottom, #13caff, #2285ff);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          //text-shadow: 0px 1px 5px rgba(2, 11, 41, 0.4);
          span{
            font-size: 14px;
          }
        }
        &>.item-line-right{
          width: calc(100% - 121px);
          display: flex;
          align-items: center;
          .item-progress{
            width: 112px;
            height: 20px;
            display: flex;
            border: 1px solid #238eff;
            background: rgba(35,142,255,0.3);

            margin-right: 7px;
            &>span{
              display: block;
              width: 0px;
              transition: all 0.3s;
              height: 100%;
              background: url("../../assets/imgs/homeSupervise-item/progress.png") ;
            }
          }
          .item-progress-title{
            font-size: 20px;
            background: linear-gradient(to bottom, #00ffff, #238eff);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            &>span{
              font-size: 14px;
            }
          }
        }
        .pie-chart{
          width: 90px !important
        }
      }
    }
    &>li:nth-child(1){
      .item-title{
        background: url("../../assets/imgs/homeSupervise-item/T+0.png") no-repeat center center;
      }
    }
    &>li:nth-child(2){
      .item-title{
        background: url("../../assets/imgs/homeSupervise-item/T+1.png") no-repeat center center;
      }
    }
    &>li:nth-child(3){
      .item-title{
        background: url("../../assets/imgs/homeSupervise-item/T+(N-1).png") no-repeat center 2px;
      }
    }
  }
}
</style>
